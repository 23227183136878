<template>
    <div>
        <b-row>
            <b-col cols="4">
                <b-card class="row1">
                    <b-card-title>健康度</b-card-title>
                    <b-row>
                        <b-col cols="5">
                            <b-alert show variant="primary" class="mt-3">
                                <div class="alert-body">
                                    <h1
                                        style="font-size: 2.5rem"
                                        class="mb-0 font-digit"
                                    >
                                        {{ healthInfo.score }}%
                                    </h1>
                                    <b-icon-star-fill
                                        v-for="(
                                            _, index
                                        ) of healthInfo.start_num"
                                        :key="index"
                                        variant="success"
                                    ></b-icon-star-fill>
                                    <b-icon-star-half
                                        v-for="(
                                            _, index
                                        ) of healthInfo.start_half_num"
                                        :key="index"
                                        variant="success"
                                    ></b-icon-star-half>
                                    <b-icon-star
                                        v-for="(
                                            _, index
                                        ) of healthInfo.start_empty_num"
                                        :key="index"
                                        variant="success"
                                    ></b-icon-star>
                                </div>
                            </b-alert>
                            <p class="mt-2">
                                {{ healthInfo.description }}
                            </p>
                        </b-col>
                        <b-col cols="7">
                            <div id="container3" style="height: 15rem"></div>
                        </b-col>
                    </b-row>

                    <b-row class="mt-1">
                        <b-col cols="12">
                            <div class="border rounded p-1">
                                <b-row>
                                    <b-col cols="4">
                                        <div class="d-flex align-items-center">
                                            <b-avatar
                                                rounded
                                                size="28"
                                                variant="light-primary"
                                            >
                                                <b-icon-hdd-rack-fill
                                                    scale="1.25"
                                                ></b-icon-hdd-rack-fill>
                                            </b-avatar>
                                            <span class="ml-50">安全性</span>
                                        </div>
                                        <h2 class="mt-50 font-digit">
                                            {{ topInfos.sec.sec_score }}%
                                        </h2>
                                        <b-progress
                                            :value="topInfos.sec.sec_score"
                                            max="100"
                                            height=".5rem"
                                            variant="primary"
                                        ></b-progress>
                                    </b-col>

                                    <b-col cols="4">
                                        <div class="d-flex align-items-center">
                                            <b-avatar
                                                rounded
                                                size="28"
                                                variant="light-info"
                                            >
                                                <b-icon-hdd-rack-fill
                                                    scale="1.25"
                                                ></b-icon-hdd-rack-fill>
                                            </b-avatar>
                                            <span class="ml-50">舒适性</span>
                                        </div>
                                        <h2 class="mt-50 font-digit">
                                            {{ topInfos.sec.sec_score }}%
                                        </h2>
                                        <b-progress
                                            :value="topInfos.sec.sec_score"
                                            max="100"
                                            height=".5rem"
                                            variant="info"
                                        ></b-progress>
                                    </b-col>

                                    <b-col cols="4">
                                        <div class="d-flex align-items-center">
                                            <b-avatar
                                                rounded
                                                size="28"
                                                variant="light-success"
                                            >
                                                <b-icon-hdd-rack-fill
                                                    scale="1.25"
                                                ></b-icon-hdd-rack-fill>
                                            </b-avatar>
                                            <span class="ml-50">节能性</span>
                                        </div>
                                        <h2 class="mt-50 font-digit">
                                            {{ topInfos.sec.sec_score }}%
                                        </h2>
                                        <b-progress
                                            :value="topInfos.sec.sec_score"
                                            max="100"
                                            height=".5rem"
                                            variant="success"
                                        ></b-progress>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

            <b-col cols="4">
                <b-card class="row1">
                    <b-card-title>风险数</b-card-title>
                    <b-row>
                        <b-col cols="7">
                            <b-alert show variant="primary">
                                <div class="alert-body">
                                    <h1
                                        style="font-size: 2.5rem"
                                        class="mb-0 mt-25 font-digit"
                                    >
                                        {{
                                            translateUnitToK(
                                                riskRecordInfo.total
                                            )
                                        }}
                                    </h1>
                                    <p>风险总数</p>
                                </div>
                            </b-alert>

                            <h2 class="mb-0 font-digit">
                                {{
                                    translateUnitToK(
                                        riskRecordInfo.risk_device_total
                                    )
                                }}
                            </h2>
                            <p>风险设备</p>

                            <b-row class="mt-2">
                                <b-col cols="4">
                                    <span
                                        class="bullet bullet-danger bullet-sm mr-50"
                                    ></span>
                                    严重
                                    <h3 class="font-digit">
                                        {{
                                            translateUnitToK(
                                                riskRecordInfo.serious
                                            )
                                        }}
                                    </h3>
                                </b-col>
                                <b-col cols="4">
                                    <span
                                        class="bullet bullet-warning bullet-sm mr-50"
                                    ></span>
                                    一般
                                    <h3 class="font-digit">
                                        {{
                                            translateUnitToK(
                                                riskRecordInfo.commonly
                                            )
                                        }}
                                    </h3>
                                </b-col>
                                <b-col cols="4">
                                    <span
                                        class="bullet bullet-info bullet-sm mr-50"
                                    ></span>
                                    提醒
                                    <h3 class="font-digit">
                                        {{
                                            translateUnitToK(
                                                riskRecordInfo.remind
                                            )
                                        }}
                                    </h3>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col cols="5">
                            <div id="container6" style="height: 14rem"></div>
                        </b-col>
                    </b-row>

                    <b-row class="mt-2">
                        <b-col cols="4">
                            <div
                                class="d-flex align-items-center border rounded p-1"
                            >
                                <b-avatar
                                    rounded
                                    size="36"
                                    variant="light-warning"
                                >
                                    <b-icon-bell-fill />
                                </b-avatar>
                                <div class="ml-1">
                                    <p class="my-0">待处理</p>
                                    <h3 class="my-0 font-digit">0</h3>
                                </div>
                            </div>
                        </b-col>

                        <b-col cols="4">
                            <div
                                class="d-flex align-items-center border rounded p-1"
                            >
                                <b-avatar
                                    rounded
                                    size="32"
                                    variant="light-success"
                                >
                                    <b-icon-check2-square />
                                </b-avatar>
                                <div class="ml-75">
                                    <p class="my-0">已推送</p>
                                    <h3 class="my-0 font-digit">0</h3>
                                </div>
                            </div>
                        </b-col>

                        <b-col cols="4">
                            <div
                                class="d-flex align-items-center border rounded p-1"
                            >
                                <b-avatar
                                    rounded
                                    size="32"
                                    variant="light-secondary"
                                >
                                    <b-icon-trash-fill />
                                </b-avatar>
                                <div class="ml-75">
                                    <p class="my-0">已忽略</p>
                                    <h3 class="my-0 font-digit">0</h3>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

            <b-col cols="4">
                <b-card class="row1">
                    <b-card-title>诊断数</b-card-title>
                    <b-row>
                        <b-col cols="6">
                            <h1
                                style="font-size: 2.5rem"
                                class="mt-5 mb-0 font-digit"
                            >
                                {{ translateUnitToK(topInfos.data_total) }}
                            </h1>
                            <p>诊断数据总量</p>

                            <h1 class="mt-3 mb-0 font-digit">
                                {{ translateUnitToK(topInfos.point_total) }}
                            </h1>
                            <p>诊断总点位数</p>
                        </b-col>
                        <b-col cols="6">
                            <div class="d-flex align-items-center">
                                <b-avatar
                                    rounded
                                    size="28"
                                    variant="light-primary"
                                >
                                    <b-icon-hdd-rack-fill
                                        scale="1.25"
                                    ></b-icon-hdd-rack-fill>
                                </b-avatar>
                                <span class="ml-50">即时诊断</span>
                            </div>
                            <div
                                class="d-flex align-items-end justify-content-between mt-50"
                            >
                                <span>风险率</span>
                                <h2 class="mb-0 font-digit">
                                    {{
                                        (
                                            (topInfos.immed_fault_total /
                                                topInfos.immed_total) *
                                            100
                                        ).toFixed(0)
                                    }}%
                                </h2>
                            </div>

                            <b-progress
                                :value="
                                    (
                                        (topInfos.immed_fault_total /
                                            topInfos.immed_total) *
                                        100
                                    ).toFixed(0)
                                "
                                max="100"
                                height=".5rem"
                                variant="primary"
                                class="my-50"
                            ></b-progress>

                            <div
                                class="d-flex align-items-center justify-content-between"
                            >
                                <span>风险数</span>
                                <span>{{ topInfos.immed_total }}</span>
                            </div>

                            <div
                                class="d-flex align-items-center justify-content-between"
                            >
                                <span>诊断数</span>
                                <span>{{ topInfos.immed_fault_total }}</span>
                            </div>

                            <div
                                class="d-flex align-items-center justify-content-between"
                            >
                                <span>规则数</span>
                                <span>{{ topInfos.immed_rule_total }}</span>
                            </div>

                            <div class="d-flex align-items-center mt-3">
                                <b-avatar
                                    rounded
                                    size="28"
                                    variant="light-info"
                                >
                                    <b-icon-hdd-rack-fill
                                        scale="1.25"
                                    ></b-icon-hdd-rack-fill>
                                </b-avatar>
                                <span class="ml-50">延时诊断</span>
                            </div>
                            <div
                                class="d-flex align-items-end justify-content-between mt-50"
                            >
                                <span>风险率</span>
                                <h2 class="mb-0 font-digit">
                                    {{
                                        (
                                            (topInfos.delay_fault_total /
                                                topInfos.delay_total) *
                                            100
                                        ).toFixed(0)
                                    }}%
                                </h2>
                            </div>
                            <b-progress
                                :value="
                                    (
                                        (topInfos.delay_fault_total /
                                            topInfos.delay_total) *
                                        100
                                    ).toFixed(2)
                                "
                                max="100"
                                height=".5rem"
                                variant="info"
                                class="my-50"
                            ></b-progress>

                            <div
                                class="d-flex align-items-center justify-content-between"
                            >
                                <span>风险数</span>
                                <span>{{ topInfos.delay_total }}</span>
                            </div>

                            <div
                                class="d-flex align-items-center justify-content-between"
                            >
                                <span>诊断数</span>
                                <span>{{ topInfos.delay_fault_total }}</span>
                            </div>

                            <div
                                class="d-flex align-items-center justify-content-between"
                            >
                                <span>规则数</span>
                                <span>{{ topInfos.delay_rule_total }}</span>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="6">
                <b-card class="row2">
                    <b-card-title>风险统计</b-card-title>
                    <b-row>
                        <b-col cols="5">
                            <div class="d-flex align-items-center mt-5">
                                <h1
                                    style="font-size: 2.375rem"
                                    class="my-0 font-digit"
                                >
                                    {{
                                        translateUnitToK(
                                            riskStatics.day_date_count
                                        )
                                    }}
                                </h1>
                                <b-badge
                                    v-if="riskStatics.MoM > 0"
                                    variant="light-danger"
                                    class="ml-50"
                                    >+{{ (riskStatics.MoM * 100).toFixed(2) }}%
                                </b-badge>
                                <b-badge
                                    v-if="riskStatics.MoM < 0"
                                    variant="light-success"
                                    class="ml-50"
                                    >{{ (riskStatics.MoM * 100).toFixed(2) }}%
                                </b-badge>
                            </div>
                            <p>今日风险累计</p>
                            <div class="d-flex mt-2">
                                <div>
                                    <h2 class="my-0 font-digit">
                                        {{
                                            translateUnitToK(
                                                riskStatics.month_date_count
                                            )
                                        }}
                                    </h2>
                                    <p>本月风险累计</p>
                                </div>

                                <div class="ml-4">
                                    <h2 class="my-0 font-digit">
                                        {{
                                            translateUnitToK(
                                                riskStatics.year_date_count
                                            )
                                        }}
                                    </h2>
                                    <p>年度风险累计</p>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="7">
                            <b-tabs fill>
                                <b-tab
                                    @click="
                                        handleChangeRiskStatics(
                                            riskStatics.year_data
                                        )
                                    "
                                    title="近12月"
                                    active
                                >
                                </b-tab>
                                <b-tab
                                    @click="
                                        handleChangeRiskStatics(
                                            riskStatics.day_14_data
                                        )
                                    "
                                    title="近14日"
                                ></b-tab>
                                <b-tab
                                    @click="
                                        handleChangeRiskStatics(
                                            riskStatics.hour_24_data
                                        )
                                    "
                                    title="近24时"
                                ></b-tab>

                                <div
                                    id="container1"
                                    style="height: 16rem"
                                ></div>
                            </b-tabs>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

            <b-col cols="6">
                <b-card class="row2">
                    <b-card-title>风险分类</b-card-title>
                    <b-row>
                        <b-col cols="5">
                            <h1
                                style="font-size: 2.375rem"
                                class="my-0 mt-2 font-digit"
                            >
                                {{
                                    agentPie[3]
                                        ? agentPie[3].reduce(
                                              (x, y) =>
                                                  x.num ? x.num : x + y.num,
                                              0
                                          )
                                        : 0
                                }}
                            </h1>
                            <p>风险合计</p>

                            <div
                                id="container5"
                                style="height: 12rem"
                                class="mt-2"
                            ></div>
                        </b-col>
                        <b-col cols="6" offset="1">
                            <b-tabs fill>
                                <b-tab
                                    @click="handleClickChangePieData(0)"
                                    title="程度"
                                    active
                                >
                                </b-tab>
                                <b-tab
                                    @click="handleClickChangePieData(4)"
                                    title="系统"
                                ></b-tab>
                                <b-tab
                                    @click="handleClickChangePieData(5)"
                                    title="类型"
                                ></b-tab>

                                <div
                                    id="container2"
                                    style="height: 16rem"
                                ></div>
                            </b-tabs>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <!-- <b-row>
      <b-col cols="3">
        <b-card class="row3">
          <b-card-title>风险事件</b-card-title>

          <vue-perfect-scrollbar id="project-fault-detail-operation-scroll-notice1" @mouseenter="
                        scroll_stop(
                            '#project-fault-detail-operation-scroll-notice1'
                        )
                        " @mouseleave="
                            scroll_active(
                                '#project-fault-detail-operation-scroll-notice1'
                            )
                            " style="height: 395px">
            <app-timeline>
              <app-timeline-item :variant="item.degree === '重要'
                                ? 'danger'
                                : item.degree === '一般'
                                    ? 'info'
                                    : 'warning'
                                " v-for="(item, index) in store.state.diagnosis
                                    .notices" @click="handleToFaultDeviceInfo(item.id)" :key="'agent_' + index">
                <div>
                  {{ item.eqname }} {{ item.function_name }}
                </div>

                <div>
                  {{ item.fault_level }} / {{ item.system }} /
                  {{ item.degree }}
                </div>

                <div class="text-muted">
                  <small>{{ item.create_date }}</small>
                </div>
              </app-timeline-item>
            </app-timeline>
          </vue-perfect-scrollbar>
        </b-card>
      </b-col>

      <b-col cols="6">
        <b-card class="row3">
          <b-card-title>风险热力图</b-card-title>
          <heat-map :heatmapHeight="400"></heat-map>
        </b-card>
      </b-col>

      <b-col cols="3">
        <b-card class="row3">
          <b-card-title>风险设备</b-card-title>
          <vue-perfect-scrollbar style="height: 390px">
            <b-list-group flush>
              <b-list-group-item v-for="(item, index) of riskDevices" :key="index"
                                 @click="handleToFaultDeviceList(item.eqname)"
                                 class="d-flex justify-content-between align-items-center" style="border: 0">
                                <span class="d-flex align-items-center"><b-icon-gear-wide-connected class="mr-1"/>
                                    {{ item.eqname }}</span>
                <b-badge variant="light-primary" pill>
                  {{ item.total }}
                </b-badge>
              </b-list-group-item>
            </b-list-group>
          </vue-perfect-scrollbar>
        </b-card>
      </b-col>
    </b-row> -->
    </div>
</template>


<script>
import { Column, Pie, Gauge, RadialBar, Bar, Liquid } from "@antv/g2plot";
import {
    onMounted,
    ref,
    reactive,
    toRefs,
    computed,
} from "@vue/composition-api";
import { scroll_active, scroll_stop } from "@/libs/utils/autoScroll";
import store from "@/store";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import FunctionEntry from "@/views/front/cabr/diagnosis/dashboard/components/FunctionEntry.vue";
import {
    getDiagnosisRiskDevices,
    getDiagnosisRiskRecordInfo,
    getDiagnosisRiskStatics,
} from "@/api/diagnosis";
import router from "@/router";
import moment from "moment";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { showToast } from "@/libs/utils/showToast";
import DataVisual from "@/views/front/cabr/diagnosis/dashboard/components/DataVisual.vue";
import HeatMap from "@/views/front/cabr/diagnosis/risk/components/heatmap.vue";

export default {
    name: "test-g2-plot",
    components: {
        HeatMap,
        DataVisual,
        FunctionEntry,
        AppTimeline,
        AppTimelineItem,
        VuePerfectScrollbar,
    },
    setup() {
        // 首页顶部信息
        const event = reactive({
            projectOperation: [
                {
                    time: "2023-01-01",
                    title: "风险名称",
                    subtitle: "风险描述",
                    degree: "严重",
                },
                {
                    time: "2023-02-01",
                    title: "风险名称",
                    subtitle: "风险描述",
                    degree: "一般",
                },
                {
                    time: "2023-01-01",
                    title: "风险名称",
                    subtitle: "风险描述",
                    degree: "提醒",
                },
                {
                    time: "2023-02-01",
                    title: "风险名称",
                    subtitle: "风险描述",
                    degree: "提醒",
                },
                {
                    time: "2023-01-01",
                    title: "风险名称",
                    subtitle: "风险描述",
                    degree: "严重",
                },
            ],
            project_id: null,
            topInfos: {
                health: {
                    date: "",
                    degree: "",
                    score: 0,
                },
                sec: {
                    date: "",
                    sec_score: 100,
                },
                data_total: 0,
                point_total: 0,
                immed_total: 0,
                immed_fault_total: 0,
                immed_rule_total: 0,
                delay_total: 0,
                delay_fault_total: 0,
                delay_rule_total: 0,
            },
            healthInfo: {
                description: "智能分析中，请稍后...",
                score: 100,
                start_num: 5,
                start_half_num: 0,
                start_empty_num: 0,
            },
            riskRecordInfo: {
                commonly: 0,
                remind: 0,
                serious: 0,
                total: 0,
                risk_device_total: 0,
                device_total: 0,
            },
            riskStatics: {
                year_data: [],
                day_14_data: [],
                hour_24_data: [],
                day_date_count: 0,
                month_date_count: 0,
                year_date_count: 0,
                MoM: 0,
            },
            agentPie: [],
            riskDevices: [],
        });
        const gauge = ref(null);
        const liquidPlot = ref(null);
        const columnPlot = ref(null);
        const piePlot = ref(null);
        const bar = ref(null);
        const translateUnitToK = (num) => {
            if (num < 1000) return num;
            return (
                new Intl.NumberFormat("ja-JP").format((num / 1000).toFixed(1)) +
                "k"
            );
        };

        getDiagnosisRiskRecordInfo().then((res) => {
            event.riskRecordInfo = res.data;
            if (liquidPlot.value) {
                liquidPlot.value.update({
                    statistic: {
                        title: "风险率",
                        content: {
                            formatter: () =>
                                Math.ceil(
                                    (event.riskRecordInfo.risk_device_total /
                                        event.riskRecordInfo.device_total) *
                                        100
                                ) + "%",
                        },
                    },
                    percent:
                        (event.riskRecordInfo.risk_device_total /
                            event.riskRecordInfo.device_total) *
                        100,
                });
            }
        });

        getDiagnosisRiskDevices().then((res) => {
            console.log("获取到数据", res);
            event.riskDevices = res.data;
            console.log(event.riskDevices, "event.riskDevices");
        });

        const handleToFaultDeviceList = (eqname) => {
            router.push({
                name: "FunMenu_AgentList",
                params: {
                    eqname,
                },
            });
        };
        const handleToFaultDeviceInfo = (id) => {
            router.push({
                name: "AgentDetail",
                params: {
                    id,
                },
            });
        };

        const handleToFaultList = () => {
            router.push({
                name: "index-agent-list",
            });
        };

        const numberFormat = (value) => {
            if (!value) return [0, ""];
            let unit = "";
            let k = 10000,
                sizes = ["", "万", "亿", "万亿"],
                i;
            if (value < k) {
                value = value;
            } else {
                i = Math.floor(Math.log(value) / Math.log(k));
                value = (value / Math.pow(k, i)).toFixed(2);
                unit = sizes[i];
            }
            return [value, unit];
        };

        const handleChangeRiskStatics = (data) => {
            columnPlot.value.changeData(data);
        };
        store.dispatch("diagnosis/fetchNotice", 7);
        const handleClickChangePieData = (index) => {
            if (piePlot.value) {
                piePlot.value.destroy();
                // console.log(piePlot.value, 'piePlot.valuepiePlot.value')
                // return;
                if (index == 4) {
                    // piePlot.value.options.colorField = 'system'
                    piePlot.value = new Pie("container2", {
                        appendPadding: 10,
                        data: event.agentPie[index],
                        angleField: "num",
                        colorField: "system",
                        radius: 1,
                        innerRadius: 0.6,
                        label: {
                            type: "inner",
                            offset: "-50%",
                            content: "{value}",
                            style: {
                                textAlign: "center",
                                fontSize: 14,
                            },
                        },
                        pieStyle: {
                            lineWidth: 0,
                        },
                        interactions: [
                            { type: "element-selected" },
                            { type: "element-active" },
                        ],
                        statistic: {
                            title: false,
                        },
                    });
                } else if (index == 5) {
                    // piePlot.value.options.colorField = 'type'
                    piePlot.value = new Pie("container2", {
                        appendPadding: 10,
                        data: event.agentPie[index],
                        angleField: "num",
                        colorField: "type",
                        radius: 1,
                        innerRadius: 0.6,
                        label: {
                            type: "inner",
                            offset: "-50%",
                            content: "{value}",
                            style: {
                                textAlign: "center",
                                fontSize: 14,
                            },
                        },
                        pieStyle: {
                            lineWidth: 0,
                        },
                        interactions: [
                            { type: "element-selected" },
                            { type: "element-active" },
                        ],
                        statistic: {
                            title: false,
                        },
                    });
                    // piePlot.value.updateConfig({
                    //   angleField: "num",
                    //   colorField: "type",
                    // })
                } else {
                    // piePlot.value.options.colorField = 'degree'
                    piePlot.value = new Pie("container2", {
                        appendPadding: 10,
                        data: event.agentPie[index],
                        angleField: "num",
                        colorField: "degree",
                        radius: 1,
                        innerRadius: 0.6,
                        label: {
                            type: "inner",
                            offset: "-50%",
                            content: "{value}",
                            style: {
                                textAlign: "center",
                                fontSize: 14,
                            },
                        },
                        pieStyle: {
                            lineWidth: 0,
                        },
                        interactions: [
                            { type: "element-selected" },
                            { type: "element-active" },
                        ],
                        statistic: {
                            title: false,
                        },
                    });
                    // piePlot.value.updateConfig({
                    //   angleField: "num",
                    //   colorField: "degree",
                    // })
                }
                piePlot.value.render();
            }
        };

        const getAgentPie = () => {
            const project_id = event.project_id;
            store
                .dispatch("projects/fetchAgentPie", project_id)
                .then((response) => {
                    console.log("饼图数据", response.data);
                    event.agentPie = response.data;
                    if (piePlot.value) {
                        piePlot.value.changeData(event.agentPie[0]);
                    }
                    if (bar.value) {
                        bar.value.changeData(
                            event.agentPie[3].sort((x, y) => y.num - x.num)
                        );
                    }
                })
                .catch((error) => {
                    console.log("请求失败");
                    showToast(error);
                });
        };
        getAgentPie();

        getDiagnosisRiskStatics().then((res) => {
            event.riskStatics = res.data;
            if (columnPlot.value) {
                columnPlot.value.changeData(res.data.year_data);
            }
        });

        const getIndexTopInfos = () => {
            const project_id = event.project_id;
            store
                .dispatch("projects/getIndexTopInfos")
                .then((response) => {
                    console.log("hahah", response);
                    event.topInfos = response.data;
                    event.healthInfo.score = Math.ceil(
                        event.topInfos.health.score
                    );
                    event.healthInfo.start_num = Math.floor(
                        event.topInfos.health.score / 20
                    );
                    event.healthInfo.start_half_num = Math.ceil(
                        event.topInfos.health.score % 1
                    );
                    event.healthInfo.start_empty_num =
                        5 -
                        event.healthInfo.start_num -
                        event.healthInfo.start_half_num;
                    if (gauge.value) {
                        // gauge.value.percent = event.healthInfo.score / 100
                        gauge.value.update({
                            statistic: {
                                title: "",
                                content: {
                                    style: {
                                        fontSize: "24px",
                                        lineHeight: "44px",
                                        color: "#4B535E",
                                    },
                                    formatter: () =>
                                        Math.ceil(event.topInfos.health.score) +
                                        "%",
                                },
                            },
                            percent:
                                Math.ceil(event.topInfos.health.score) / 100,
                        });
                    }
                    switch (event.topInfos.health.degree) {
                        case "较差":
                            event.healthInfo.description =
                                "健康度较差，风险极高，需立即处理。";
                            break;
                        case "及格":
                            event.healthInfo.description =
                                "健康度欠佳，风险频繁，需及时处理。";
                            break;
                        case "优秀":
                            event.healthInfo.description =
                                "健康度优秀，设备运行良好，请继续保持。";
                            break;
                        case "良好":
                            event.healthInfo.description =
                                "健康度良好，请持续关注设备运行状态。";
                            break;
                        default:
                            event.healthInfo.description = "目前为止数据";
                    }
                })
                .catch((error) => {
                    console.log("请求失败");
                    showToast(error);
                });
        };

        getIndexTopInfos();

        const getCurrentDate = () => {
            return moment().format("YYYY-MM-DD HH:mm:ss");
        };

        onMounted(() => {
            scroll_active("#project-detail-operation-scroll-notice1");
        });

        return {
            ...toRefs(event),
            scroll_active,
            scroll_stop,
            numberFormat,
            handleChangeRiskStatics,
            handleClickChangePieData,
            translateUnitToK,
            getCurrentDate,
            handleToFaultDeviceList,
            handleToFaultDeviceInfo,
            handleToFaultList,
            piePlot,
            columnPlot,
            liquidPlot,
            store,
            gauge,
            bar,
        };
    },

    mounted() {
        const data1 = [];
        const data2 = [];

        const data4 = [
            { name: "安全", star: 297 },
            { name: "舒适", star: 506 },
            { name: "节能", star: 805 },
        ];

        const data5 = [
            { year: "数据质量", value: 764 },
            { year: "硬件联动", value: 118 },
            { year: "运行效果", value: 14 },
            { year: "控制逻辑", value: 11 },
        ];

        this.columnPlot = new Column("container1", {
            data: data1,
            xField: "date",
            yField: "value",
            label: {
                position: "middle",
                style: {
                    fill: "#FFFFFF",
                    opacity: 0.6,
                },
            },
            xAxis: {
                label: {
                    autoHide: true,
                    autoRotate: false,
                },
                line: null,
                grid: null,
                tickLine: null,
            },
            yAxis: {
                line: null,
                grid: null,
                label: null,
            },
            columnStyle: {
                radius: [5, 5, 5, 5],
            },
            meta: {
                type: {
                    alias: "类别",
                },
                sales: {
                    alias: "销售额",
                },
            },
        });

        this.piePlot = new Pie("container2", {
            appendPadding: 10,
            data: data2,
            angleField: "num",
            colorField: "degree",
            radius: 1,
            innerRadius: 0.6,
            label: {
                type: "inner",
                offset: "-50%",
                content: "{value}",
                style: {
                    textAlign: "center",
                    fontSize: 14,
                },
            },
            pieStyle: {
                lineWidth: 0,
            },
            interactions: [
                { type: "element-selected" },
                { type: "element-active" },
            ],
            statistic: {
                title: false,
            },
        });

        this.gauge = new Gauge("container3", {
            percent: 100,
            type: "meter",
            innerRadius: 0.75,
            range: {
                ticks: [0, 1 / 3, 2 / 3, 1],
                color: ["#ea5455", "#ff9f43", "#28c76f"],
            },
            indicator: {
                pointer: {
                    style: {
                        stroke: "#D0D0D0",
                    },
                },
                pin: {
                    style: {
                        stroke: "#D0D0D0",
                    },
                },
            },
            axis: {
                label: {
                    formatter(v) {
                        return Number(v) * 100;
                    },
                },
                subTickLine: {
                    count: 3,
                },
            },
        });

        /* const radialBar = new RadialBar("container4", {
data: data4,
xField: "name",
yField: "star",
maxAngle: 270, //最大旋转角度,
radius: 0.8,
innerRadius: 0.2,
barStyle: {
lineCap: "round",
},
}); */

        this.bar = new Bar("container5", {
            data: [],
            xField: "num",
            yField: "primary_class",
            xAxis: {
                grid: null,
                line: null,
            },
            yAxis: {
                grid: null,
                line: null,
                tickLine: null,
            },
            label: {
                position: "middle",
                style: {
                    fill: "#FFFFFF",
                    opacity: 0.6,
                },
            },
            seriesField: "primary_class",
            legend: false,
            barStyle: {
                radius: [10, 10, 10, 10],
            },
        });

        this.liquidPlot = new Liquid("container6", {
            percent: 0,
            outline: {
                border: 4,
                distance: 0,
            },
            wave: {
                length: 128,
            },
            statistic: {
                title: {
                    content: "风险率",
                },
            },
        });

        this.columnPlot.render();
        this.piePlot.render();
        this.gauge.render();
        /* radialBar.render(); */
        this.bar.render();
        this.liquidPlot.render();
    },
};
</script>

<style lang="scss" scoped>
/* Lucas */
.row0 {
    height: calc(100vh - 137px);
}

.row1 {
    height: 420px;
}

.row2 {
    height: 366px;
    /* height: calc(100vh - 590px); */
}

.row3 {
    height: 480px;
}
</style>
